<template>
  <Banner></Banner>
</template>

<script>
import {mapActions} from "vuex";
import Banner from "@/components/success/Banner";

export default {
  name: "CheckoutSuccess",
  components: { Banner },
  methods:{
    ...mapActions(['checkoutRegisterSuccess']),
  },
  async mounted() {
    await this.checkoutRegisterSuccess(this.$route.query.id);
  },
};
</script>
