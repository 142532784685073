<template>
  <v-container class="mt-sm-10 pa-0 red-gradient-container" fluid>
    <v-container class="pt-sm-10 pt-md-16 px-xl-16">
      <v-row class="px-xl-16">
        <v-col sm="6" md="5">

          <v-sheet class="mt-16 font-weight-bold green--text text--darken-2 rounded-xl px-3 py-1"
                   color="white"
                   flat
                   width="350"
          >
            <v-icon color="green">
              mdi-check-circle
            </v-icon>
            Your order has been <br class="hidden-sm-and-up" />successfully placed
          </v-sheet>

          <div class="my-5 text-h4 text-md-h3 font-weight-bold white--text pb-3">
            Thank you for choosing Learning<span class="grey--text">Plus</span>.
          </div>

          <div class="pp-hr"></div>

          <div class="my-5 text-h5 font-weight-bold grey--text text--lighten-1">
            You can now take the learning with you.
          </div>

          <div class="pr-10 white--text">
            We have sent an email with your order confirmation and receipt. If the email hasn't arrived within two minutes, please check your spam folder.
          </div>
        </v-col>

        <v-col sm="6" md="7" class="pa-0">
          <v-img
              src="/images/shades/success-01.png"
              alt="Checkout LearningPlus Successfully"
              height="500"
              contain
              class="mb-0 pb-0"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Banner",
};
</script>
